import { createApp } from 'vue'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router/router.js'
import { createMetaManager, resolveOption, defaultConfig } from 'vue-meta'
const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.use(createMetaManager({...defaultConfig}, resolveOption((prevValue, context) => {
    const { uid = 0 } = context.vm || {}
    if (!prevValue || prevValue < uid) {
      return uid
    }
})))

new Promise((r) => {
    if("production" != process.env.NODE_ENV){

        import('element-plus/dist/index.css').then(r);
    }else {
        r();
    }
}).then(() => {

    app.mount('#app')
});