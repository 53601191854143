import { createRouter, createWebHistory} from 'vue-router'

const routes = require('./router.config').routes;

const router = createRouter({
    history: createWebHistory(),
	mode: 'history',
    routes,
})

let count = 0;
router.beforeEach(() => {

    return count++ == 0;
})

export default router