
const routes = [
	{
	    path:"/",
	    name:"人员信息在线生成",
	    component: () => import('../components/PI'),
		meta: {
			htmlMeta: {
				keywords: "小工具在线,在线小工具,在线工具,开发人员工具,开发在线工具,测试在线工具,测试人员工具,模拟数据,模拟数据生成,测试数据,测试数据生成,个人资料,人名,企业名称,手机号,身份证号,银行卡号,uuid,密码,字符串,编码,解码,json,http",
				description: "小工具在线,是一个免费的在线工具网站，提供日常生活、工作常用工具,UUID生成,JSON格式化,JSON校验,随机密码生成,字符串生成,url编码,url解码,http模拟请求,模拟数据生成，测试数据生成,手机号,身份证号,银行卡号,姓名,现住址,邮编,在线加解密,存储单位转换,编码转换",
			}
		}
	},
	{
	    path:"/json",
	    name:"json在线格式化",
	    component: () => import('../components/json'),
		meta: {
			htmlMeta: {
				keywords: "json,json格式化,json压缩,json转义,json去转义,json美化",
				description: "json在线格式化,json在线压缩,json在线转义,json在线去转义,json格式化,json压缩,json转义,json去转义"
			}
		}
	},
	{
	    path:"/url",
	    name:"url在线编码解码",
	    component: () => import('../components/codes'),
		meta: {
			htmlMeta: {
				keywords: "url,url编码,url解码,url编码解码,encode,decode,encodeURL,decodeURL",
				description: "url在线编码解码,url编码解码,根据url编码规则,用encodeURI或decodeURI方法进行编解码,不会对特殊符号编码"
			}
		}
	},
	{
		path:"/idcard",
		name:"身份证号在线生成",
		component: () => import('../components/idcard'),
		meta: {
			htmlMeta: {
				keywords: "身份证号,身份证号生成,身份证号在线生成,身份证号测试数据生成,身份证号模拟数据生成",
				description: "身份证号在线生成,身份证号生成,根据身份证号18位规则,进行动态模拟身份证号生成,1-2位表示省级行政区,3-4位表示地级行政区,5-6位表示县级行政区,7-14位表示编码对象出生的年、月、日,15-17位表示在同一地址码所标识的区域范围内，对同年、月、日出生的人员编定的顺序号,18位根据前面十七位数字码,按照ISO 7064:1983.MOD 11-2校验码计算出来的检验码"
			}
		}
	},
    {
        path:"/uuid",
        name:"uuid在线生成",
        component: () => import('../components/UUID'),
		meta: {
			htmlMeta: {
				keywords: "uuid,16位uuid,32位uuid,uuid生成,uuid动态生成",
				description: "uuid在线生成,uuid生成,根据uuid16位及32位UUID是通用唯一识别码的规则,动态生成uuid,全局唯一标识符，定义为一个字符串主键，采用32位数字组成，编码采用16进制，定义了在时间和空间都完全惟一的系统信息"
			}
		}
    },
	{
	    path:"/pwdstring",
	    name:"密码字符串在线生成",
	    component: () => import('../components/pwdstring'),
		meta: {
			htmlMeta: {
				keywords: "密码,动态密码,6位密码,8位密码,12未密码,大写密码,小写密码,大小写数字组合密码,随机生成密码",
				description: "密码字符串在线生成,密码在线生成,字符串在线生成,随机数在线生成,16位主键在线生成,密码、字符串在线生成,可根据前缀、后缀、包含、排除、位数、是否重复、长度、数字、大小写字符、数字等组合条件动态生成"
			}
		}
	},
 	{
	    path:"/curl",
	    name:"curl请求在线生成",
	    component: () => import('../components/http'),
		meta: {
			htmlMeta: {
				keywords: "delete,put,http,https,curl,post,get,http请求,https请求,curl请求,post请求,get请求,delete请求,put请求,lixu请求转码",
				description: "curl请求在线生成,curl请求生成,服务器命令生成,根据curl命定规则,通过页面方式自动生成,delete,put,post,get,curl请求，并支持配置用户名,密码,cookie等信息"
			}
		}
	},
	{
	    path:"/cron",
	    name:"cron表达式在线生成",
	    component: () => import('../components/cron'),
		meta: {
			htmlMeta: {
				keywords: "cron,cron表达式,cron表达式反解析,cron表达式生成,cron表达式校验,cron表达式生成结果",
				description: "cron表达式在线生成,cron表达式反解析,cron表达式生成,定时任务生成,根据cron规则,通过图形化页面方式,生成cron表达式,支持反向解析,cron表达式校验,执行时间生成"
			}
		}
	},
	{
	    path:"/storage",
	    name:"存储单位在线转换",
	    component: () => import('../components/storage'),
		meta: {
			htmlMeta: {
				keywords: "bit,byte,KB,MB,GB,TB,PB,EB,ZB,YB,存储转转,存储单位转换,比特,字节,千字节,兆字节,千兆字节,太字节,拍字节,艾字节,泽字节,尧字节",
				description: "存储单位在线转换,存储单位转换,根据存储单位转换规则,计算不同单位转换数量,支持结果复制功能"
			}
		}
	},
	{
	    path:"/regexp",
	    name:"正则表达式在线验证",
	    component: () => import('../components/regexp'),
		meta: {
			htmlMeta: {
				keywords: "正则表达式在线验证,正则表达式验证,手机号正则,邮箱正则,身份证号正则,密码正则,邮编正则,电话号码正则",
				description: "正则表达式在线验证,正则表达式验证,根据正则规则,在线校验表达式正确性,支持常用及自定义"
			}
		}
	},
	{
	    path:"/timestamp",
	    name:"时间戳在线转换",
	    component: () => import('../components/timestamp'),
		meta: {
			htmlMeta: {
				keywords: "timestamp,date,时间戳在线转换,时间戳转日期,日期转时间戳,当前时间时间戳,当前时间日期",
				description: "时间戳在线转换,时间戳转换,自动获取当前时间戳,当前时间自动获取,根据时间戳自动转换日期,根据日期自动转换时间戳"
			}
		}
	},
	{
	    path:"/encrypt",
	    name:"数据在线加解密",
	    component: () => import('../components/encrypt'),
		meta: {
			htmlMeta: {
				keywords: "数据在线加解密,数据在线加密,数据在线解密,SHA1,SHA224,SHA256SHA384,SHA512,MD5,HmacSHA1,HmacSHA224,HmacSHA256,HmacSHA384,HmacSHA512,HmacMD5,base64,AES,DES,Rabbit,RC4,TripleDES,加密,解密,在线加密,在线解密",
				description: "数据在线加解密,数据在线加密,数据在线解密,SHA224,SHA256SHA384,SHA512,MD5,HmacSHA1,HmacSHA224,HmacSHA256,HmacSHA384,HmacSHA512,HmacMD5,base64,AES,DES,Rabbit,RC4,TripleDES等算法,在线加解密"
			}
		}
	},
	{
	    path:"/portscan",
	    name:"域名端口在线扫描",
	    component: () => import('../components/portscan'),
		meta: {
			htmlMeta: {
				keywords: "互联网、局域网、在线端口扫描、端口扫描、常见端口、Ftp(21)、Ssh(22)、Telnet(23)、Smtp(25)、Http(80、8080、8090)、Pop3(110)、IMAP(143)、Https(443)、共享(445)、远程桌面(3389)、MSSQL(3306)、Oracle (1521)、Oracle (6543)",
				description: "域名端口在线扫描,局域网端口在线扫描,域名端口扫描,ip端口扫描,在线端口扫描,Ftp(21),Ssh(22),Telnet(23),Smtp(25),Http(80,8080,8090),Pop3(110),IMAP(143),Https(443),共享(445),远程桌面(3389),MSSQL(3306),Oracle (1521),Oracle (6543)"
			}
		}
	},
	{
		path:"/name",
		name:"企业名称在线生成",
		component: () => import('../components/name'),
		meta: {
			htmlMeta: {
				keywords: "姓名,人名,企业名称生成,单位,单位名称",
				description: "企业名称在线生成,人员姓名在线生成,根据百家姓及名库,动态生成人员姓名,根据企业名称注册规则,动态组合企业单位名称"
			}
		}
	},
	{
		path:"/photo",
		name:"手机号在线生成",
		component: () => import('../components/mobilephone'),
		meta: {
			htmlMeta: {
				keywords: "手机号,手机号生成,手机号在线生成,手机号测试数据生成,手机号模拟数据生成",
				description: "手机号在线生成,手机号生成,根据手机号码生成规则,动态生成移动、联通、电信等运行商模式手机号,手机号码为11位,3位网号+4位HLR号+4位的个人代码"
			}
		}
	},
	{
		path:"/bankcard",
		name:"银行卡号在线生成",
		component: () => import('../components/bankcard'),
		meta: {
			htmlMeta: {
				keywords: "银行卡号,银行卡号生成,银行卡号在线生成,银行卡号测试数据生成，银行卡号拟数据生成",
				description: "银行卡号在线生成,银行卡号生成,根据银行卡号19位规则,进行动态模拟银行卡号生成,卡号的前6位数字代表发卡行标识代码，也叫BIN号，不同的BIN号代表了不同的银行卡组织和卡片级别"
			}
		}
	},
	{
		path:"/doc",
		name:"文本在线生成",
		component: () => import('../components/doc'),
		meta: {
			htmlMeta: {
				keywords: "文本生成,字数生成,测试字数生成,测试文本生成,模拟文本生成,模拟字数生成",
				description: "文本在线生成,根据字数生成文档,根据测试人员,常用文本框长度限制,生成指定字数文本"
			}
		}
	},
	{
		path:"/unicode",
		name:"unicode/ascii在线编码转换",
		component: () => import('../components/codes'),
		meta: {
			htmlMeta: {
				keywords: "unicode,ascii,编码转换,在线编码转换,unicode在线,ascii在线,unicode转ascii,ascii转unicode,unicode在线转ascii,ascii在线转unicode",
				description: "unicode/ascii在线编码转换,unicode在线编码,unicode在线解码,unicode在线转ascii,ascii在线转unicode"
			}
		}
	},
	{
		path:"/chinese",
		name:"unicode/中文在线编码转换",
		component: () => import('../components/codes'),
		meta: {
			htmlMeta: {
				keywords: "chinese,unicode,编码转换,在线编码转换,chinese在线,unicode在线,chinese转unicode,unicode转chinese,chinese在线转unicode,unicode在线转chinese",
				description: "unicode/中文在线编码转换,chinese在线转unicode,unicode在线转chinese"
			}
		}
	},
	{
		path:"/utf8",
		name:"utf8/中文在线编码转换",
		component: () => import('../components/codes'),
		meta: {
			htmlMeta: {
				keywords: "utf8,中文编码转换,编码转换,在线编码转换,utf8在线,中文编码在线,utf8转中文,中文转utf8,utf8在线转中文,中文在线转utf8",
				description: "utf8/中文在线编码转换,utf8在线转中文,中文在线转utf8"
			}
		}
	},
	{
		path:"/gbk",
		name:"gbk/utf8在线编码转换",
		component: () => import('../components/codes'),
		meta: {
			htmlMeta: {
				keywords: "gbk,utf8,编码转换,在线编码转换,gbk在线,utf8编码在线,gbk转utf8,utf8转gbk,utf8在线转gbk,gbk在线转utf8",
				description: "gbk/utf8在线编码转换,utf8在线转gbk,gbk在线转utf8"
			}
		}
	},
	{
		path:"/keyboard",
		name:"键盘按键KeyCode码",
		component: () => import('../components/keyboard'),
		meta: {
			htmlMeta: {
				keywords: "keyboard,键盘按键KeyCode码",
				description: "keyboard,键盘按键KeyCode码"
			}
		}
	},
];

// commonJS
module.exports = {routes};