<template>
	<metainfo>
    </metainfo>
  <div class="common-layout">
    <el-container>
	<el-menu router="true" :default-active="getIndex($route)" text-color="#409EFF" class="el-menu-demo" mode="horizontal">
		<el-menu-item v-for = "(item, index) in $router.options.routes" :key="index" :index="item.path">
			<a :href = "item.path" style="text-decoration: none;">{{item.name}}</a>
		</el-menu-item>
	</el-menu>	
  <el-main class="div-main">
	<el-config-provider :locale="zhCn">
		<router-view></router-view>
	</el-config-provider>
  </el-main>
  <el-footer class="footer">
	<div class="tip div-center">
		<p style="font-size: 18px;">使用说明:</p> 
		<blockquote>
		<p><i>
			生成信息，是通过相关信息规则随机、虚拟生成，并非真实信息，仅供测试使用，请勿用于非法用途。
		</i></p>
		</blockquote>
		<p>Copyright©2022 <span style="color: #50bfff;" @click="open()">卡迪兄弟</span> 京ICP备2022020435号</p>
		<!-- <div>
		  <el-link  :underline="false">友情链接：</el-link>
		  <el-link target="_blank" href="https://www.aliyun.com/minisite/goods?userCode=nh71fhaa&share_source=copy_link" type="primary">阿里云</el-link>
		</div> -->
	</div>
	</el-footer>
  </el-container>
  </div>
  
</template>

<script>
export default {
	name: 'App',
  methods: {
        open() {
          this.$alert('卡迪兄弟工作室，是由行业内多名资深IT从业人员组成，利用业余时间做一些工具，供大家使用，欢迎各位网友提出需求及建议。e-mail：xgjzxcn@yeah.net', '工作室简介', {
            confirmButtonText: '确定'
          });
        }
	}
  
}
</script>
<script setup>
import {useMeta, getCurrentManager} from 'vue-meta'
import {useRoute, useRouter} from 'vue-router'
import {onMounted} from 'vue'
import zhCn from "element-plus/lib/locale/lang/zh-cn";

let getIndex = (route) => {
	route = currentRoute(route);
	return route ? route.path : "";
}

let curRoute = useRoute();

let currentRoute = (route) => {
	route = route ? route : curRoute;
	
	if(!route.matched[0]){
		
		return;
	}

	route = route.matched[0];

	return route.aliasOf ? route.aliasOf : route
}

onMounted(() => {

	let manager = getCurrentManager();

	useRouter().isReady().then(()=>{

		let route = currentRoute();

		let meta = [];
		for(let key in route.meta.htmlMeta){
			meta.push({
				name: key,
				content: route.meta.htmlMeta[key]
			});
		}

		useMeta({
			title: route.name+"-小工具在线(xgjzx.cn)",
			meta: meta
		}, manager);
		
		document.dispatchEvent(new Event("render-event"))
	});


});

</script>

<style>
	.div-main{
	 min-height: calc(100vh - 245px);
	}
	.footer{
	/**position: fixed;*/
	left: 0px;
	bottom: 0px;
	height: 150px;
	width: 100%;
	}
	.div-center{
		margin: 0 auto;
		text-align: center;
	}
	.tip {
	    padding: 8px 16px;
	    background-color: #ecf8ff;
	    border-radius: 4px;
	    border-left: 5px solid #50bfff;
	    margin: 20px 0;
	}
</style>
